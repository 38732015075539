import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import HTMLContent from '~/components/Content';
import Layout from '~/components/Layout';
import './about-page.scss';

export const AboutPageTemplate = props => {
  const {
    page: { frontmatter, html },
  } = props;

  return (
    <article className="about-page">
      <div className="about-page__info">
        <h1>{frontmatter.heading}</h1>
        {html && <HTMLContent className="about-page__address" content={html} />}
        <p className="about-page__phone">{frontmatter.phone}</p>
        <a href={`mailto:${frontmatter.email}`} className="about-page__email">
          {frontmatter.email}
        </a>
      </div>
      <div className="img-container">
        <img className="image" src={frontmatter.image} alt="Contacto" />
      </div>
      {/* <iframe
        className="about-page__map"
        src="https://snazzymaps.com/embed/231512"
        title="map"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      /> */}
    </article>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const { frontmatter } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={`${frontmatter.heading} :: Mitá`} />
        <meta name="description" content={`${frontmatter.heading} :: Mitá`} />
        <title>{frontmatter.heading} :: Mitá</title>
      </Helmet>
      <AboutPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        heading
        phone
        email
        image
      }
    }
    ...LayoutFragment
  }
`;
